<template>
    <page-header-wrapper>
        <template slot="extra">
            
        </template>
        <a-card :bordered="false">
            <div class="table-page-search-wrapper">
                <a-form layout="inline">
                    <a-row :gutter="24">
                        <a-col :span="24" style="display: flex;flex-wrap: wrap;">
                            <a-form-item label="性别" class="sex" style="margin-right: 10px;">
                                <a-select v-model="queryParam.sex" style="width: 120px">
                                    <a-select-option value="0">全部性别</a-select-option>
                                    <a-select-option value="男">男</a-select-option>
                                    <a-select-option value="女">女</a-select-option>
                                </a-select>
                            </a-form-item>
                            <a-form-item label="年龄" class="sex" style="margin-right: 10px;">
                                <a-select v-model="queryParam.age" style="width: 120px">
                                    <a-select-option value="0">全部年龄</a-select-option>
                                    <a-select-option value="30岁以下">30岁以下</a-select-option>
                                    <a-select-option value="30─40岁">30─40岁</a-select-option>
                                    <a-select-option value="40─50岁">40─50岁</a-select-option>
                                    <a-select-option value="50─60岁">50─60岁</a-select-option>
                                    <a-select-option value="60岁以上">60岁以上</a-select-option>
                                </a-select>
                            </a-form-item>
                            <a-form-item label="居住时间" style="margin-right: 10px;">
                                <a-select v-model="queryParam.year" style="width: 120px">
                                    <a-select-option value="0">全部时间</a-select-option>
                                    <a-select-option value="1年以下">1年以下</a-select-option>
                                    <a-select-option value="2─3年">2─3年</a-select-option>
                                    <a-select-option value="4─6年">4─6年</a-select-option>
                                    <a-select-option value="7─10年">7─10年</a-select-option>
                                    <a-select-option value="10年以上">10年以上</a-select-option>
                                </a-select>
                            </a-form-item>
                            <a-form-item label="供水类型" style="margin-right: 10px;">
                                <a-select v-model="queryParam.type" style="width: 120px">
                                    <a-select-option value="0">全部类型</a-select-option>
                                    <a-select-option value="直接供水">直接供水</a-select-option>
                                    <a-select-option value="高层二次供水">高层二次供水</a-select-option>
                                </a-select>
                            </a-form-item>
                            <a-form-item label="常住人口" style="margin-right: 10px;">
                                <a-select v-model="queryParam.population" style="width: 120px">
                                    <a-select-option value="0">全部人口</a-select-option>
                                    <a-select-option value="1人">1人</a-select-option>
                                    <a-select-option value="2─3人">2─3人</a-select-option>
                                    <a-select-option value="4─5人">4─5人</a-select-option>
                                    <a-select-option value="6人及以上">6人及以上</a-select-option>
                                </a-select>
                            </a-form-item>
                            <span class="table-page-search-submitButtons">
                                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                                <a-button style="margin-left: 8px" @click="reset">重置</a-button>
                            </span>
                        </a-col>
                    </a-row>
                </a-form>
            </div>

            <s-table ref="table" size="default" :rowKey="(record) => record.id" :columns="columns" :data="loadData" showPagination="auto" :pageNum="pageNum">
                <span slot="serial" slot-scope="text, record, index">
                    {{ index + 1 }}
                </span>
				<span slot="nickname" slot-scope="record">
					<span>{{ record.nickname }} </span>
				</span>
                <span slot="sex" slot-scope="record">
					<span>{{ record.sex||'-' }}</span>
				</span>
                <span slot="age" slot-scope="record">
                    {{ record.age||'-' }}
                </span>
                <span slot="address" slot-scope="record">
                    <span v-if="record.area">{{ record.area }}区</span>{{ record.house||'-' }}
                    <span v-if="record.type">({{ record.type||'-' }})</span>
                </span>
                <span slot="population" slot-scope="record">
                    {{ record.population||'-' }}
                </span>
                <span slot="year" slot-scope="record">
                    {{ record.year||'-' }}
                </span>
                <span slot="suggest" slot-scope="record">
                    <ellipsis :length="20" tooltip>{{ record.suggest||'-' }}</ellipsis>
                </span>
                <span slot="create_time" slot-scope="record">
                    {{ record.create_time|time }}
                </span>
                <span slot="action" slot-scope="text, record">
                    <a @click="showInfo(record)" style="margin-right:10px;">详情</a>
                </span>
            </s-table>

            <a-drawer
                title="详情"
                placement="right"
                :visible="showDrawer"
                @close="onClose" width="400px"
            >
                <div v-if="info">
                    <div class="info_list">
                        <div class="info_list_lable">微信昵称:</div>
                        <div class="info_list_main">{{info.nickname}}</div>
                    </div>
                    <div class="info_list">
                        <div class="info_list_lable">性别:</div>
                        <div class="info_list_main">{{info.sex||'-'}}</div>
                    </div>
                    <div class="info_list">
                        <div class="info_list_lable">年龄:</div>
                        <div class="info_list_main">{{info.age||'-'}}</div>
                    </div>
                    <div class="info_list">
                        <div class="info_list_lable">住址:</div>
                        <div class="info_list_main">
                            <span v-if="info.area">{{info.area}}区</span>
                            {{ info.house||'-' }}
                            <span v-if="info.type">({{ info.type||'-' }})</span>
                        </div>
                    </div>
                    <div class="info_list">
                        <div class="info_list_lable">常住人口:</div>
                        <div class="info_list_main">{{info.population||'-'}}</div>
                    </div>
                    <div class="info_list">
                        <div class="info_list_lable">居住时间:</div>
                        <div class="info_list_main">{{info.year||'-'}}</div>
                    </div>
                    <div class="info_list">
                        <div class="info_list_lable">建议:</div>
                        <div class="info_list_main">{{info.suggest||'-'}}</div>
                    </div>
                    <div class="info_list">
                        <div class="info_list_lable">提交时间:</div>
                        <div class="info_list_main" v-if='info.create_time>0'>{{info.create_time|time}}</div>
                        <div class="info_list_main" v-else>-</div>
                    </div>
                </div>
        </a-drawer>
        </a-card>
    </page-header-wrapper>
</template>

<script>
    import moment from 'moment'
    import { Modal,message } from 'ant-design-vue';
    import {
        STable,
        Ellipsis
    } from '@/components'
    import {
        _getJoinList
    } from '@/api/statistics'
	let timer
    const columns = [
        {
            title: '序号',
            scopedSlots: {
                customRender: 'serial'
            },
            align:'center',
            width:'90px'
        },
        // {
        //     title: '昵称',
        //     dataIndex: '',
        //     width:'110px',
		// 	scopedSlots:{
		// 		customRender:'nickname'
		// 	},
        //     align:'center'
        // },
        {
            title: '性别',
            dataIndex: '',
            align:'center',
            scopedSlots: {
                customRender: 'sex'
            },
            width:'80px',
        },
        {
            title: '年龄',
            dataIndex: '',
            scopedSlots: {
                customRender: 'age'
            },
            align:'center',
            width:'100px'
        },
        {
            title: '住址',
            dataIndex: '',
            scopedSlots: {
                customRender: 'address'
            },
            align:'center'
        },
        {
            title: '常住人口',
            dataIndex: '',
            scopedSlots: {
                customRender: 'population'
            },
            align:'center',
            width:'110px'
        },
        {
            title: '居住时间',
            dataIndex: '',
            scopedSlots: {
                customRender: 'year'
            },
            align:'center',
            width:'110px'
        },
        {
            title: '建议',
            dataIndex: '',
            scopedSlots: {
                customRender: 'suggest'
            },
            align:'center',
            // width:'120px'
        },
        // {
        //     title: '提交时间',
        //     dataIndex: '',
        //     scopedSlots: {
        //         customRender: 'create_time'
        //     },
        //     align:'center',
        //     width:'110px'
        // },
        {
            title: '操作',
            dataIndex: '',
            scopedSlots: {
                customRender: 'action'
            },
            align:'center',
            width:'80px'
        }
    ]

    export default {
        name: '',
        components: {
            STable,
            Ellipsis
        },
        data() {
            this.columns = columns
            return {
                visible: false,
                // 查询参数
                queryParam: {
                    sex: '0',
                    age:'0',
                    population:'0',
                    type:'0',
                    year:'0'
                },
                // 加载数据方法 必须为 Promise 对象
                loadData: parameter => {
                    //console.log('parameter',parameter)
                    const requestParameters = Object.assign({}, parameter, this.queryParam)
                    //console.log('requestParameters',requestParameters)
                    return _getJoinList(requestParameters)
                        .then(res => {
                            return res.data
                        })
                },
                pageNum:1,
                showDrawer:false,
                info:null,
            }
        },
		crteated(){
			let current = getUrlKey('p')||0
			//console.log('current',current)
			this.pageNum = Number(current)
		},
        methods: {
            showInfo(data){
                this.info = data;
                this.showDrawer = true;
            },
            onClose(){
                this.info = null;
                this.showDrawer = false;
            },
            reset() { //重置
                this.queryParam.sex = '0';
                this.queryParam.age = '0';
                this.queryParam.population ='0';
                this.queryParam.type = '0';
                this.queryParam.year = '0';
                this.$refs.table.refresh(true);
            }
        },
        filters: {
            time(t) {
                if (t) {
                    return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:ss')
                } else {
                    return t
                }
            }
        }
    }
</script>


<style scoped>
.info_list{
    display: flex;
    align-items: center;
    padding:10px 0;
  }
  .info_list .info_list_lable{
    min-width:100px;
    width:100px;
  }
  .info_list .info_list_main{
    min-width:200px;
    width:100%;
  }
  .sex /deep/ .ant-form-item-label{
    min-width: 55px!important;

  }
</style>